import React, { useState } from "react";
import {
  Box,
  CircularProgress,
  Dialog,
  FormControl,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Typography,
} from "@mui/material";
import { Button } from "react-bootstrap";
import SignInModal from "./SignInModal";
import axiosInstance from "src/utils/axios";
import useAuth from "src/hooks/useAuth";
import { useSnackbar } from "notistack";
import Transition from "src/utils/dialog-animation";
import ConfirmPopUP from "./confirmPopUP";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import PaymentSection from "./metamaskPurchase";
const SignUpModal = ({ isOpen, setIsSignUpOpen, setIsSignInOpen, uname }) => {
  const [stage, setStage] = useState(1); // to control the current step

  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    mobile_number: "",
    city: "",
    state: "",
    country: "",
    email: "",
    confirm_email: "",
    password: "",
    confirm_password: "",
    referral: uname ? uname : "XORBIT",
  });
  const [email, setEmail] = useState();

  const initialFormData = {
    first_name: "",
    last_name: "",
    mobile_number: "",
    city: "",
    state: "",
    country: "",
    email: "",
    confirm_email: "",
    password: "",
    confirm_password: "",
    referral: uname ? uname : "XORBIT",
  };

  const [errors, setErrors] = useState({});

  const closeModal = () => {
    setIsSignUpOpen(false);
    setFormData(initialFormData);
    setErrors({});
    setStage(1);
  };

  const validateStepOne = () => {
    const newErrors = {};
    if (!formData.first_name) newErrors.first_name = "First Name is required";
    if (!formData.last_name) newErrors.last_name = "Last Name is required";
    if (!formData.mobile_number)
      newErrors.mobile_number = "Mobile Number is required";
    if (!formData.city) newErrors.city = "City is required";
    if (!formData.state) newErrors.state = "State is required";
    if (!formData.country) newErrors.country = "Country is required";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const validateStepTwo = () => {
    const newErrors = {};
    if (!formData.email) newErrors.email = "Email is required";
    if (formData.email !== formData.confirm_email)
      newErrors.confirm_email = "Email must match";
    if (!formData.password) newErrors.password = "Password is required";
    if (formData.password !== formData.confirm_password)
      newErrors.confirm_password = "Passwords must match";
    if (!formData.referral) newErrors.referral = "Referral code is required";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });

    let newErrors = { ...errors };
    if (value.trim() !== "") {
      delete newErrors[name];
    }
    if (name === "confirm_password" && value !== formData.password) {
      newErrors.confirm_password = "Passwords must match";
    }

    if (name === "confirm_email" && value !== formData.email) {
      newErrors.confirm_email = "Email must match";
    }

    setErrors(newErrors);
  };

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const handlePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const handleNext = () => {
    if (validateStepOne()) {
      setStage(2);
    }
  };

  const handleBack = () => {
    setStage(1);
  };
  const { register } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const [openPopup, setOpenPopup] = useState(false);
  const [loading, setLoading] = useState(false);

  const [message, setMessage] = useState(false);
  const handleOpenPopup = () => {
    setOpenPopup(true);
  };
  const handleClosePopup = () => setOpenPopup(false);
  const handleSubmit = async (e) => {
    e.preventDefault();
    const reqData = new FormData();
    Object.entries(formData).forEach(([k, v]) => reqData.append(k, v));
    if (validateStepTwo()) {
      try {
        setLoading(true);
        const { data, status } = await axiosInstance.post(
          `api/user-register`,
          reqData
        );
        if (status === 200) {
          setLoading(false);
          setMessage(data.message);
          setFormData({});
          closeModal();
          setEmail(formData.email);
          setStage(1);
          handleOpenPopup();
        }
      } catch (err) {
        setLoading(false);

        // enqueueSnackbar(err.message, { variant: "error" });
        console.log(err.errors);
        setErrors(err.errors);
        // handleErrors(err);
      }
    }
  };

  const handleOpen = () => {
    closeModal();
    setIsSignInOpen(true);
  };

  return (
    <>
      <Dialog
        TransitionComponent={Transition}
        fullWidth
        maxWidth="sm"
        open={isOpen}
        onClose={closeModal}
        aria-labelledby="add-article-category"
      >
        <div className="modal-overlay signInUp" onClick={closeModal}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <button className="close-btn" onClick={closeModal}>
              <svg
                width="24"
                height="24"
                viewBox="0 0 16 16"
                fill="currentColor"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8 8.707l3.646 3.647.708-.707L8.707 8l3.647-3.646-.707-.708L8 7.293 4.354 3.646l-.707.708L7.293 8l-3.646 3.646.707.708L8 8.707z"
                  fillRule="evenodd"
                  clipRule="evenodd"
                ></path>
              </svg>
            </button>
            <Typography sx={{ textTransform: "uppercase", color: "#fff" }}>
              Create an account
            </Typography>
            <form>
              {stage === 1 ? (
                <>
                  <Box sx={{ mt: 4 }}>
                    <input
                      type="text"
                      name="first_name"
                      placeholder="First Name"
                      value={formData.first_name}
                      onChange={handleChange}
                      required
                    />
                    {errors.first_name && (
                      <p style={{ color: "red" }}>{errors.first_name}</p>
                    )}
                  </Box>
                  <Box>
                    <input
                      type="text"
                      name="last_name"
                      placeholder="Last Name"
                      value={formData.last_name}
                      onChange={handleChange}
                      required
                    />
                    {errors.last_name && (
                      <p style={{ color: "red" }}>{errors.last_name}</p>
                    )}
                  </Box>
                  <Box>
                    <input
                      type="text"
                      name="mobile_number"
                      placeholder="Mobile Number"
                      value={formData.mobile_number}
                      onChange={handleChange}
                      required
                    />
                    {errors.mobile_number && (
                      <p style={{ color: "red" }}>{errors.mobile_number}</p>
                    )}
                  </Box>
                  <Box>
                    <input
                      type="text"
                      name="city"
                      placeholder="City"
                      value={formData.city}
                      onChange={handleChange}
                      required
                    />
                    {errors.city && (
                      <p style={{ color: "red" }}>{errors.city}</p>
                    )}
                  </Box>
                  <Box>
                    <input
                      type="text"
                      name="state"
                      placeholder="State"
                      value={formData.state}
                      onChange={handleChange}
                      required
                    />
                    {errors.state && (
                      <p style={{ color: "red" }}>{errors.state}</p>
                    )}
                  </Box>
                  <Box>
                    <input
                      type="text"
                      name="country"
                      placeholder="Country"
                      value={formData.country}
                      onChange={handleChange}
                      required
                    />
                    {errors.country && (
                      <p style={{ color: "red" }}>{errors.country}</p>
                    )}
                  </Box>
                  <Button
                    className="butonstyle1"
                    type="button"
                    onClick={handleNext}
                  >
                    Next
                  </Button>
                </>
              ) : (
                <>
                  <Box sx={{ mt: 4 }}>
                    <input
                      type="text"
                      name="email"
                      placeholder="eMail ID"
                      value={formData.email}
                      onChange={handleChange}
                      required
                    />
                    {errors.email && (
                      <p style={{ color: "red" }}>{errors.email}</p>
                    )}
                  </Box>
                  <Box sx={{ mt: 4 }}>
                    <input
                      type="text"
                      name="confirm_email"
                      placeholder="Confirm eMail ID"
                      value={formData.confirm_email}
                      onChange={handleChange}
                      required
                    />
                    {errors.confirm_email && (
                      <p style={{ color: "red" }}>{errors.confirm_email}</p>
                    )}
                  </Box>

                  <Box>
                    <Input
                      fullWidth
                      type={showPassword ? "text" : "password"}
                      name="password"
                      placeholder="Passkey"
                      value={formData.password}
                      onChange={handleChange}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            onClick={handlePasswordVisibility}
                            edge="end"
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      }
                      required
                      sx={{
                        border: "none", // Remove border
                        "&:before, &:after": {
                          display: "none", // Remove the underline
                        },
                      }}
                    />
                    {errors.password && (
                      <p style={{ color: "red" }}>{errors.password}</p>
                    )}
                  </Box>
                  <Box>
                    <Input
                      fullWidth
                      type={showConfirmPassword ? "text" : "password"}
                      name="confirm_password"
                      placeholder="Confirm Passkey"
                      value={formData.confirm_password}
                      onChange={handleChange}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            onClick={handleConfirmPasswordVisibility}
                            edge="end"
                          >
                            {showConfirmPassword ? (
                              <VisibilityOff />
                            ) : (
                              <Visibility />
                            )}
                          </IconButton>
                        </InputAdornment>
                      }
                      required
                      sx={{
                        border: "none", // Remove border
                        "&:before, &:after": {
                          display: "none", // Remove the underline
                        },
                      }}
                    />
                    {errors.confirm_password && (
                      <p style={{ color: "red" }}>{errors.confirm_password}</p>
                    )}
                    {/* <input
                      type="password"
                      name="confirm_password"
                      placeholder="Confirm Passkey"
                      value={formData.confirm_password}
                      onChange={handleChange}
                      required
                    />
                    {errors.confirm_password && (
                      <p style={{ color: "red" }}>{errors.confirm_password}</p>
                    )} */}
                  </Box>
                  <Box>
                    <input
                      disabled={uname}
                      name="referral"
                      placeholder="Referral Code"
                      value={formData.referral}
                      onChange={handleChange}
                      required
                    />
                    {errors.referral && (
                      <p style={{ color: "red" }}>{errors.referral}</p>
                    )}
                  </Box>
                  <Box sx={{ display: "flex", gap: "5px" }}>
                    <Button
                      className="butonstyle1 butonstyle2"
                      type="button"
                      onClick={handleBack}
                    >
                      Back
                    </Button>
                    <Button
                      className="butonstyle1"
                      type="submit"
                      onClick={handleSubmit}
                      disabled={loading}
                    >
                      {loading ? (
                        <CircularProgress size={15} sx={{ color: "white" }} />
                      ) : (
                        "Submit"
                      )}
                    </Button>
                  </Box>
                </>
              )}
              <Typography
                sx={{ marginTop: "20px", fontSize: "14px", color: "#fff" }}
              >
                Already have an account?{" "}
                <span>
                  <a href="#" onClick={handleOpen} style={{ color: "#958262" }}>
                    Sign In
                  </a>
                </span>
              </Typography>
            </form>
          </div>
        </div>
      </Dialog>
      <PaymentSection
        email={email}
        open={openPopup}
        onClose={handleClosePopup}
      />
    </>
  );
};

export default SignUpModal;
