import axiosInstance from "src/utils/axios";
import fetchUser from "src/utils/fetchUser";

const genReqData = (inputData) => {
  const reqData = new FormData();
  Object.entries(inputData).forEach(([k, v]) => {
    if (Boolean(v)) reqData.append(k, v);
  });
  return reqData;
};

const placeOrderPending = async (inputData) => {
  const reqData = genReqData(inputData);
  const { status, data } = await axiosInstance.post("/api/register", reqData);

  return { status: status === 200 && data.status, data: data };
};

export default placeOrderPending;
