import { yupResolver } from "@hookform/resolvers/yup";
import {
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { FormProvider, RHFTextField } from "src/components/hook-form";
import useAuth from "src/hooks/useAuth";
import { CloseIcon } from "src/theme/overrides/CustomIcons";
import axiosInstance from "src/utils/axios";
import * as Yup from "yup";
import "./style.css";
import { Button } from "react-bootstrap";
import placeOrderPending from "./placeOrderPending";
import handleCryptoPayments from "src/pages/user/onlineStore/checkout/components/payment/utils/handle-crypto-payments";
import ConfirmPopUP from "../confirmPopUP";
import Transition from "src/utils/dialog-animation";
const PaymentSection = ({ email, open, onClose }) => {
  const [openPopup, setOpenPopup] = useState(false);
  const [message, setMessage] = useState(false);

  const handleOpenPopup = () => {
    setOpenPopup(true);
  };
  const handleClosePopup = () => setOpenPopup(false);
  const { logout } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const ReviewSchema = Yup.object().shape({
    no_of_stakes: Yup.number()
      .required("Number os stake is required")
      .typeError("Number os stake is required"),
  });

  const defaultValues = {
    no_of_stakes: "",
  };

  const methods = useForm({
    resolver: yupResolver(ReviewSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { errors, isSubmitting },
  } = methods;
  const [loading, setLoading] = useState();
  const onSubmit = async (inputData) => {
    setLoading(true);
    const { no_of_stakes: stakes } = inputData;
    try {
      let txnHash = null;
      txnHash = await handleCryptoPayments(stakes);
      if (txnHash) {
        const { data } = await placeOrderPending({
          no_of_stakes: stakes,
          hash: txnHash,
          email: email,
        });
        if (data) {
          setMessage(data.message);
          handleOpenPopup();
        }
      }
    } catch (err) {
      setLoading(false);
      let message = err.message;
      if (err?.code === "UNPREDICTABLE_GAS_LIMIT") {
        message =
          "Your account does not have enough balance! check your balance and try again.";
      }
      if (err?.code === "ACTION_REJECTED") {
        message = "Transaction Rejected By User";
      }
      enqueueSnackbar(message, { variant: "error" });
    }
  };

  return (
    <div>
      {" "}
      <Dialog
        className="uerPopup uerPopupBorder"
        fullWidth
        maxWidth="xs"
        open={open}
        onClose={onClose}
      >
        <DialogContent>
          <Box sx={{ textAlign: "right" }}>
            <Tooltip title="Logout">
              <IconButton
                onClick={logout}
                sx={{
                  position: "absolute",
                  top: 8,
                  right: 8,
                }}
              >
                <CloseIcon />
              </IconButton>
            </Tooltip>
          </Box>
          <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <Box sx={{ textAlign: "center", p: 2 }}>
              <Typography variant="h4" sx={{ color: "#fff" }}>
                10 USDT
              </Typography>
              <Typography
                sx={{ mb: 4, mt: 1.5, color: "#c1c1c1", fontSize: "14px" }}
              >
                BEP20 Network
              </Typography>

              <RHFTextField
                className="textFieldDesign"
                name="no_of_stakes"
                label={"Number Of Stakes"}
              />
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={loading}
                className="butonstyles3"
              >
                {loading ? (
                  <CircularProgress size={15} sx={{ color: "white" }} />
                ) : (
                  "Purchase Now"
                )}
              </Button>
            </Box>
            <Box sx={{ justifyContent: "center", display: "flex" }}>
              {loading && (
                <Typography variant="caption" color={"primary"}>
                  Payment in progress... Please wait, do not refresh the page.{" "}
                </Typography>
              )}
            </Box>
          </FormProvider>
        </DialogContent>
      </Dialog>
      <Dialog
        className="registerAlertBox"
        TransitionComponent={Transition}
        fullWidth
        maxWidth="sm"
        open={openPopup}
        onClose={handleClosePopup}
        aria-labelledby="add-article-category"
        sx={{ backgroundColor: "transparent" }}
      >
        <ConfirmPopUP message={message} />
      </Dialog>
    </div>
  );
};

export default PaymentSection;
